<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name"></component>
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name"></component>
  </router-view>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'App'
});
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  vertical-align: top;
  border: none;
}

.clearf:after,
.clearf:before {
  content: '';
  display: block;
  clear: both;
}

.van-cell:not(:last-child)::after {
  border-bottom: none !important;
}

/*  编辑器样式 */
.ql-toolbar {
  text-align: left !important;
  padding: 8px 0 !important;
}

.ql-formats {
  margin-bottom: 2px !important;
  margin-right: -4px !important;
}

/* .ql-picker-options {
  right: 30%;
} */
#ql-picker-options-0 {
  left: 20%;
}

.ql-editor {
  padding: 8px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>

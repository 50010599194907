import _ from 'lodash';

/**
 *
 * 系统工具类
 *
 *
 * */
export default {
  convertMenus(menuArr: any[]) {
    for (let i = 0; i < menuArr.length; i++) {
      let href = filterUrl(menuArr[i].code);
      if (menuArr[i].children.length != 0) {
        //把父节点的url设为空
        // menuArr[i].code = "";
        let child = menuArr[i].children;
        for (let j = 0; j < child.length; j++) {
          //把子节点的url设为 parentUrl+childUrl
          child[j].code = href.concat(filterUrl(child[j].code));
          if (child[j].children.length != 0) {
            this.convertMenus(child);
          }
        }
      }
    }

    function filterUrl(url: string) {
      if (!url) {
        return '';
      }
      if (url && !url.startsWith('/')) {
        url = '/'.concat(url);
      }
      return url;
    }

    return menuArr;
  },

  findMenuByPath(menu: any, menuArr: any[]) {
    let result = {};
    for (let i = 0; i < menuArr.length; i++) {
      if (menuArr[i].code == menu.code) {
        result = menuArr[i];
      }
      let child = menuArr[i].children;
      for (let j = 0; j < child.length; j++) {
        if (child[j].code == menu.code) {
          result = child[j];
        } else {
          this.findMenuByPath(menu, child);
        }
      }
    }

    return result;
  },

  uuid() {
    let s: any[] = [];
    let hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '_';
    let uuid = s.join('');
    return uuid;
  },

  // 生成不带下划线的uuid
  uuid_no_underline(num: number) {
    var radix = 16; //16进制
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
      i;
    radix = radix || chars.length;
    if (num) {
      for (i = 0; i < num; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  },

  /**
   * 深拷贝
   * @param data
   */
  cloneDeep(data: any) {
    return _.cloneDeep(data);
  }
};

export default {
  state: {
    isInited: false, // 项目运行后，状态改为ture, 用于判断刷新
    // pageLoading: false,
    activedThreads: 0,
    loginType: null,
    user: null,
    menus: null,
  },
  mutations: {
    SET_LOAD_STATUS(state: any, data: any) {
      state.isInited = data;
    },
    ADD_ACTIVED_THREAD(state: any, data: any = 1) {
      state.activedThreads += data;
    },
    SUB_ACTIVED_THREAD(state: any, data: any = 1) {
      state.activedThreads -= data;
    },

    SET_USER(state: any, data: any) {
      state.user = data;
    },
    SET_MENUS(state: any, data: any) {
      state.menus = data;
    },
    SET_LOGIN_TYPE(state: any, data: any) {
      state.loginType = data;
    },
  },
  actions: {
    setLoadStatus(state: any, data: any) {
      state.commit("SET_LOAD_STATUS", data);
    },
    setUser(state: any, data: any) {
      state.commit("SET_USER", data);
    },
    setMenus(state: any, data: any) {
      state.commit("SET_MENUS", data);
    },
    setLoginType(state: any, data: any) {
      state.commit("SET_LOGIN_TYPE", data);
    },
  },
  getters: {
    /**
     * @description: 页面加载状态
     * @return:
     */
    pageLoading: (state: any) => {
      return state.activedThreads != 0;
    },
    /**
     * @description: 获取登陆类型
     * @return:
     */
    loginType: (state: any) => {
      return state.loginType;
    },

    /**
     * @description: 获取菜单
     * @return:
     */
    menus: (state: any) => {
      return state.menus;
    },

    /**
     * @description: 获取用户
     * @param {type: 存储类型}
     * @return:
     */
    user: (state: any) => {
      return state.user;
    },
  },
};

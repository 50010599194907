import { FlowConfigModel, FlowConfigData } from './FlowConfigModel';
import { FlowGraphModel, FlowGraphData } from './FlowGraphModel';

export interface FlowModel {
  id?: string;
  code: string;
  name: string;
  config: FlowConfigModel; // 流程配置
  graph: FlowGraphModel; // 流程图配置
  status?: string;
  description?: string;
  applicationId?: string;
  createDate?: number;
  updateDate?: number;
}

export const FlowData = (): FlowModel => {
  let obj: FlowModel = {
    id: '',
    code: '',
    name: '',
    config: FlowConfigData(), // 流程配置
    graph: FlowGraphData(), // 流程图配置
    status: '',
    description: '',
    applicationId: ''
  };
  return obj;
};

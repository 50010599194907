import axios from 'axios';
import StorageUtil from '@/utils/StorageUtil';
import { Toast } from 'vant';
import { store as StoreIndex } from '@/store';
import LoginApi from '@/api/system/LoginApi';
//默认请求响应超时时间
axios.defaults.timeout = 3000 * 60;
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//HTTPrequest拦截
axios.interceptors.request.use(
  (config: any) => {
    // 设置token
    config.headers['Authorization'] = StorageUtil.getSessionStorage('token');
    config.headers['_zjn_oa_app_version'] = import.meta.env.VITE_APP_VERSION + '_' + new Date().getTime();
    if (config.loading != false) {
      StoreIndex.commit('ADD_ACTIVED_THREAD');
    }
    // config.headers["Authorization"] = import.meta.env.VITE_APP_TOKEN
    return config;
  },
  error => {
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);
//HTTPresponse拦截
axios.interceptors.response.use(
  (res: any) => {
    if (res.data.code == '-1') {
      // 如果code是-1，则是token失效，返回登录页
      StorageUtil.clearSessionStorage();
      let loginType = process.env.VITE_APP_LOGIN_TYPE;
      if (loginType === '0') {
        StoreIndex.commit('SUB_ACTIVED_THREAD');
      } else if (loginType === '1') {
        let ssoParams = {
          clientId: process.env.VITE_APP_CLIENT_ID,
          ssoCallBack: encodeURIComponent(window.location.href)
        };
        LoginApi.ssoDeriction(ssoParams);
      } else if (loginType === '2') {
        let url = `${process.env.VITE_APP_CECEP_SSO_HOST}/idp/oauth2/authorize?redirect_uri=${encodeURIComponent(
          window.location.href
        )}&state=123&client_id=${process.env.VITE_APP_CECEP_CLIENT_ID}&response_type=code`;
        window.location.href = url;
      } else if (loginType === '3') {
        StorageUtil.removeLocalStorage('ticket');
        StorageUtil.removeLocalStorage('oaappClientIp');
        StorageUtil.removeLocalStorage('oaappUserName');
        StorageUtil.removeLocalStorage('oaappId');
        window.location.reload();
      }
      return res.data;
    }
    if (res.config.loading != false) {
      StoreIndex.commit('SUB_ACTIVED_THREAD');
    }
    return res.data;
  },
  (error: any) => {
    if (error.config.loading != false) {
      StoreIndex.commit('SUB_ACTIVED_THREAD');
    }
    Toast.fail(`（${error.response.data.code}）${error.response.data.message}`);
    return Promise.reject(error.response.data);
  }
);

export default axios;


export interface FlowGraphModel { }

export function FlowGraphData(): FlowGraphModel {

  return {
    edges: [],
    nodes: [],
  }
};

import _ from 'lodash';
import { createI18n } from 'vue-i18n';
import StorageUtil from '@/utils/StorageUtil';

import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';

// vxe-table 多语言
import vxeTableI18nZhCN from 'vxe-table/lib/locale/lang/zh-CN';
import vxeTableI18nEnUS from 'vxe-table/lib/locale/lang/en-US';

let vantUIi18 = {
  zh: { ...zhCN },
  en: { ...enUS },
  tw: { ...zhTW }
};

let vxeTableI18n = {
  zh: vxeTableI18nZhCN,
  en: vxeTableI18nEnUS,
  tw: vxeTableI18nZhCN
};

export const curLangStorageKey = 'langCode';
let langCode = StorageUtil.getLocalStorage(curLangStorageKey);
if (!langCode) {
  langCode = 'zh';
  StorageUtil.setLocalStorage(curLangStorageKey, langCode);
}
// app.use(I18n);
const i18n = createI18n({
  locale: langCode,
  legacy: false, // you must specify 'legacy: false' option
  globalInjection: true,
  messages: _.merge(vantUIi18, vxeTableI18n)
});

export const langStorageKey = 'langs';
export const langStorageMd5Key = 'langMD5';
export const langTypeStorageKey = 'langTypes';

/**
 * 更换i18n语言
 * @param lang
 */
export function setI18nLanguage(lang: string) {
  i18n.global.locale.value = lang;
  return lang;
}

/**
 * 清理i18n浏览器缓存
 */
export function clearI18nLocalStorageCache(): void {
  localStorage.removeItem(langStorageKey);
  localStorage.removeItem(langStorageMd5Key);
}

/**
 * 设置cookie
 */
export function setI18nCookie(code: string): void {
  localStorage.setItem(curLangStorageKey, code);
}

export function mergeLocaleMessages(code: any, messages: any) {
  i18n.global.mergeLocaleMessage(code, messages);
}
export function getMessages() {
  return i18n.global.messages.value;
}

// 翻译文本
export function translateText(text: string): string {
  return i18n.global.t(text) as any;
}

export default i18n;

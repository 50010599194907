import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import RouterInterceptor from '@/interceptor/RouterInterceptor';
import CommonRouters from './common';
const routes: any[] = CommonRouters;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth'
    };
  }
});
RouterInterceptor.before(router);
RouterInterceptor.after(router);
export default router;

import StorageUtil from '@/utils/StorageUtil';
import LoginApi from '@/api/system/LoginApi';
import { store as StoreUtil } from '@/store';
import { getLanguageResourceQueryI18n, getLanguageTypeQueryList, languageResourceQueryI18nChecksum } from '@/api/system/LanguageApi';
import { getWsUrl } from '@/api/system/AssistApi';
import { langStorageKey, langStorageMd5Key, langTypeStorageKey, mergeLocaleMessages, getMessages } from '@/lang';
import _ from 'lodash';
import { env } from 'process';

let whiteRoutes = ['/login', '/register', '/500'];

export default {
  /**
   * @description: 路由跳转前操作
   * @param {}
   * @return:
   */
  before(router: any) {
    router.beforeEach(async (to: any, from: any, next: any) => {
      let customerSystem = to.query.customerSystem;
      if (customerSystem) {
        StorageUtil.setSessionStorage('customerSystem', customerSystem);
      }
      let loginType = process.env.VITE_APP_LOGIN_TYPE;
      if (whiteRoutes.includes(to.path)) {
        await this.setLangCache();
        await this.setWsUrlCache();
        next();
      } else {
        //判断登录方式
        let token = StorageUtil.getSessionStorage('token');
        if (token) {
          let isInited = StoreUtil.state.common.isInited;
          if (!isInited) {
            // 如果是刷新页面，则重新加载缓存
            await this.reloadCache(to.path);
            StoreUtil.commit('SET_LOAD_STATUS', true);
          }
          next();
        } else {
          if (loginType === '1') {
            await this.ssoHandler(to, from, next);
            // next();
          } else if (loginType === '2') {
            await this.cecepSsoHandler(to, from, next);
          } else if (loginType === '3') {
            await this.h5SsoHandler(to, from, next);
          } else {
            next('/login');
          }
        }
      }
    });
  },

  /**
   * @description: 路由跳转后操作
   * @param {}
   * @return:
   */
  after(router: any) {
    router.afterEach((to: any, from: any, next: any) => {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
    });
  },

  /**
   * @description: 处理单点登录
   * @param {type}
   * @return:
   */
  async ssoHandler(to: any, from: any, next: any) {
    //判断ssoToken地址栏是否携带
    if (to.query.token) {
      StorageUtil.setSessionStorage('token', to.query.token);
      await this.reloadCache(to.path);
      delete to.query.token;
      delete to.query.clientId;
      // next()
      next({
        path: to.path,
        query: to.query
      });
    } else {
      let ssoParams = { clientId: process.env.VITE_APP_CLIENT_ID, ssoCallBack: encodeURIComponent(window.location.href) };
      LoginApi.ssoDeriction(ssoParams);
      delete to.query.token;
      delete to.query.clientId;
    }
  },

  /**
   * @description: 处理单点登录
   * @param {type}
   * @return:
   */
  async cecepSsoHandler(to: any, from: any, next: any) {
    //判断ssoToken地址栏是否携带
    if (to.query.token) {
      StorageUtil.setSessionStorage('token', to.query.token);
      await this.reloadCache(to.path);
      delete to.query.token;
      delete to.query.clientId;
      // next()
      next({
        path: to.path,
        query: to.query
      });
    } else if (to.query.code) {
      let url = `${process.env.VITE_APP_SSO_HOST}/ecode/sso/login?code=${to.query.code}&clientId=${
        process.env.VITE_APP_CLIENT_ID
      }&redirectUrl=${encodeURIComponent(window.location.href)}`;
      window.location.href = url;
    } else {
      let url = `${process.env.VITE_APP_CECEP_SSO_HOST}/idp/oauth2/authorize?redirect_uri=${encodeURIComponent(
        window.location.href
      )}&state=123&client_id=${process.env.VITE_APP_CECEP_CLIENT_ID}&response_type=code`;
      window.location.href = url;
    }
  },

  /**
   * @description: 处理单点登录
   * @param {type}
   * @return:
   */
  async h5SsoHandler(to: any, from: any, next: any) {
    //判断ssoToken地址栏是否携带
    if (to.query.token) {
      StorageUtil.setSessionStorage('token', to.query.token);
      await this.reloadCache(to.path);
      delete to.query.token;
      delete to.query.clientId;
      // next()
      next({
        path: to.path,
        query: to.query
      });
    } else if (to.query.errorCode) {
      if (to.query.errorCode === '1') {
        StorageUtil.removeLocalStorage('ticket');
        StorageUtil.removeLocalStorage('oaappClientIp');
        StorageUtil.removeLocalStorage('oaappUserName');
        StorageUtil.removeLocalStorage('oaappId');
        window.location.reload();
      } else if (to.query.errorCode === '2') {
        StorageUtil.removeLocalStorage('ticket');
        StorageUtil.removeLocalStorage('oaappClientIp');
        StorageUtil.removeLocalStorage('oaappUserName');
        StorageUtil.removeLocalStorage('oaappId');
        window.ns.runtime.closePage();
      } else if (to.query.errorCode === '3') {
        next('/500');
      } else if (to.query.errorCode === '4') {
        next('/500');
      }
    } else {
      let ticket = localStorage.getItem('oaappTicketTemp');
      let clientIp = localStorage.getItem('oaappClientIp');
      let oaappId = localStorage.getItem('oaappId');
      if (ticket) {
        // 1: 票据失效  2: sdk失败 3：500  4:账户不匹配
        // let ssoParams = {
        //   clientId: process.env.VITE_APP_CLIENT_ID,
        //   clientIp,
        //   ticket,
        //   ssoCallBack: encodeURIComponent(window.location.href)
        // };
        // LoginApi.h5Deriction(ssoParams);

        let ssoParams = {
          clientId: process.env.VITE_APP_CLIENT_ID,
          userId: oaappId,
          ssoCallBack: encodeURIComponent(window.location.href)
        };
        LoginApi.h5DerictionByUser(ssoParams);
        delete to.query.token;
        delete to.query.clientId;
      } else {
        // next({
        //   path: to.path,
        //   query: to.query
        // });
        next('/login');
      }
    }
  },

  getSSOTicketWithName(ticketObj: any) {
    (window as any).ns.runtime.getSSOTicketWithName({
      onSuccess: (msg: any) => {
        ticketObj.ssoTicketToken = msg.obj.ssoTicket;
        ticketObj.username = msg.obj.username;
        console.log('-----------------ticket-----------------', ticketObj);
        alert('成功' + 'oaappUserName' + JSON.stringify(ticketObj.username) + 'ssoTicket :' + JSON.stringify(ticketObj.ssoTicketToken));
      },
      onFail: (msg: any) => {
        alert('失败' + JSON.stringify(msg));
      }
    });
  },

  //重新加载系统公共资源
  reloadCache(path: any) {
    return new Promise(async (resolve, reject) => {
      // 获取登录后的用户缓存
      let userCache = (await LoginApi.getLoginCache({ clientId: import.meta.env.VITE_APP_CLIENT_ID })).data;
      if (userCache) {
        // 存储个人信息
        let userInfo = userCache.user;
        userInfo.tenants = userCache.tenants;
        StoreUtil.commit('SET_USER', userInfo);
        // 存储菜单
        StoreUtil.commit('SET_MENUS', userCache.menus);
      } else {
        resolve('00001');
      }

      // 加载多语言
      let langCache = await this.setLangCache();
      if (!langCache) {
        resolve('00002');
      }

      let wsCache = await this.setWsUrlCache();
      if (wsCache) {
        resolve('00000');
      } else {
        resolve('00002');
      }

      //获取主题色
      // let themeRes = await LoginApi.themeDetail({})
      let themeRes = {
        success: true,
        code: '00000',
        message: '请求成功',
        data: {
          createTime: null,
          createAccountId: null,
          updateTime: null,
          updateAccountId: null,
          systemThemeId: '1352509947693961217',
          themeCode: 'WarmOrange',
          themeSort: null,
          themeName: '温暖橙',
          flagDefault: 1,
          mainColor: '#DF6E2C',
          auxiliaryColor: '#F38240',
          backgroundColor: '#CE6529',
          characterColor: '#7C665A',
          field1: '#FF0000',
          field2: null,
          field3: null,
          field4: null,
          namePinyinAbbrevia: null,
          namePinyin: null
        }
      };
      StorageUtil.setSessionStorage('theme', themeRes.data);
      this.setTheme(themeRes.data);
      resolve('00000');
    });
  },
  // 缓存多语言包
  setLangCache() {
    return new Promise(async (resolve, reject) => {
      let isUpdate = await this.checkLangStatus();
      if (isUpdate) {
        let langCache = await this.getLangCache();
        if (langCache) {
          resolve(true);
        }
      } else {
        let langData = StorageUtil.getLocalStorage(langStorageKey);
        if (langData) {
          for (const langCode in langData) {
            const langMessages = langData[langCode];
            mergeLocaleMessages(langCode, langMessages);
          }
          resolve(true);
        }
        // local里没有 langData 重新请求资源包
        else {
          let langCache = await this.getLangCache();
          if (langCache) {
            resolve(true);
          }
        }
      }
      resolve(false);
    });
  },
  // 判断语言包是否更新
  checkLangStatus() {
    return new Promise((resolve, reject) => {
      languageResourceQueryI18nChecksum().then((res: any) => {
        if (res.code == '1') {
          let langMd5 = res.data;
          let cacheLangMd5 = StorageUtil.getLocalStorage(langStorageMd5Key);
          if (cacheLangMd5 !== langMd5) {
            StorageUtil.setLocalStorage(langStorageMd5Key, langMd5);
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    });
  },
  // 获取语言包缓存
  getLangCache() {
    return new Promise((resolve, reject) => {
      let params = { staticFlag: false };
      let params2 = { staticFlag: true };
      Promise.all([getLanguageResourceQueryI18n(params), getLanguageResourceQueryI18n(params2), getLanguageTypeQueryList()])
        .then(([res1, res2, res3]: any[]) => {
          if (res1.code == '1' && res2.code == '1' && res3.code == '1') {
            res3.data.forEach((item: any) => {
              let langMessages = {
                ...res1.data[item.code],
                ...res2.data[item.code]
              };
              mergeLocaleMessages(item.code, langMessages);
            });
            StorageUtil.setLocalStorage(langStorageKey, getMessages());
            StorageUtil.setLocalStorage(langTypeStorageKey, res3.data);
          }
          resolve(true);
        })
        .catch(error => {
          resolve(false);
        });
    });
  },

  setWsUrlCache() {
    return new Promise(async (resolve, reject) => {
      let wsUrl = StorageUtil.getLocalStorage('wsUrl');
      if (wsUrl) {
        resolve(true);
      } else {
        getWsUrl()
          .then((res: any) => {
            if (res.code == '1') {
              StorageUtil.setLocalStorage('wsUrl', res.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(e => {
            resolve(false);
          });
      }
    });
  },

  //设置主题样式
  setTheme(themeObj: any) {
    document.documentElement.style.setProperty('--mainThemeColor', themeObj.mainColor);
    document.documentElement.style.setProperty('--secondThemeColor', themeObj.auxiliaryColor);
    document.documentElement.style.setProperty('--threeThemeColor', themeObj.backgroundColor);
    document.documentElement.style.setProperty('--fourThemeColor', themeObj.characterColor);
    document.documentElement.style.setProperty('--themeCode', themeObj.themeCode);
  }
};

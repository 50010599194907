export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Layout" */ '@/views/login/index.vue'),
    meta: {
      keepAlive: false,
      title: '登录'
    }
  },
  {
    path: '/user', // 路由未匹配到，进入这个
    name: 'pageOrg',
    component: () => import('@/views/form/components/mobile-user/index.vue'),
    meta: {
      keepAlive: false,
      title: 'org'
    }
  },
  {
    path: '/',
    name: 'Layout',
    redirect: '/workbench',
    meta: {
      keepAlive: false,
      title: '布局页面'
    }
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: () => import(/* webpackChunkName: "Layout" */ '@/views/workbench/index.vue'),
    meta: {
      keepAlive: false,
      title: '工作台'
    }
  },
  {
    path: '/undo',
    name: 'undo',
    component: () => import('@/views/workbench/need-to-done/index.vue'),
    meta: {
      keepAlive: false,
      title: '待办事项'
    }
  },
  {
    path: '/flow/task',
    name: 'approve',
    component: () => import('@/views/workbench/handle-task/index.vue'),
    meta: {
      keepAlive: true,
      title: '事项办理'
    }
  },
  {
    path: '/flow/detail',
    name: 'FlowDetail',
    component: () => import('@/views/workbench/handle-task/flow-detail.vue'),
    meta: {
      keepAlive: true,
      title: '文档详情'
    }
  },
  {
    path: '/started',
    name: 'started',
    component: () => import('@/views/workbench/start-thing/index.vue'),
    meta: {
      keepAlive: false,
      title: '发起事项'
    }
  },
  {
    path: '/done',
    name: 'done',
    component: () => import('@/views/workbench/has-done/index.vue'),
    meta: {
      keepAlive: false,
      title: '已办事项'
    }
  },
  {
    path: '/finishThing',
    name: 'finishThing',
    component: () => import('@/views/workbench/finish-thing/index.vue'),
    meta: {
      keepAlive: false,
      title: '办结事项'
    }
  },
  {
    path: '/cc',
    name: 'cc',
    component: () => import('@/views/workbench/carbon/index.vue'),
    meta: {
      keepAlive: false,
      title: '抄送事项'
    }
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/views/workbench/transfer/index.vue'),
    meta: {
      keepAlive: false,
      title: '转办事项'
    }
  },
  {
    path: '/handleTask/start',
    name: 'handleTaskStart',
    component: () => import('@/views/workbench/handle-task/flow-start.vue'),
    meta: {
      keepAlive: false,
      title: '流程发起'
    }
  },
  {
    path: '/handleTask/cc',
    name: 'handleTaskFlowCc',
    component: () => import('@/views/workbench/handle-task/flow-cc.vue'),
    meta: {
      keepAlive: false,
      title: '抄送事项'
    }
  },
  {
    path: '/handleTask/delegate',
    name: 'handleTaskFlowDelegate',
    component: () => import('@/views/workbench/handle-task/flow-delegate.vue'),
    meta: {
      keepAlive: false,
      title: '转办事项'
    }
  },
  {
    path: '/handleTask/done',
    name: 'handleTaskFlowDone',
    component: () => import('@/views/workbench/handle-task/flow-done.vue'),
    meta: {
      keepAlive: false,
      title: '已办事项'
    }
  },
  {
    path: '/handleTask/finish',
    name: 'handleTaskFlowFinish',
    component: () => import('@/views/workbench/handle-task/flow-finish.vue'),
    meta: {
      keepAlive: false,
      title: '办结事项'
    }
  },
  {
    path: '/handleTask/request',
    name: 'handleTaskFlowRequest',
    component: () => import('@/views/workbench/handle-task/flow-request.vue'),
    meta: {
      keepAlive: false,
      title: '我的发起'
    }
  },
  {
    path: '/handleTask/undo',
    name: 'handleTaskFlowUndo',
    component: () => import('@/views/workbench/handle-task/flow-undo.vue'),
    meta: {
      keepAlive: false,
      title: '待办事项'
    }
  },
  {
    path: '/newProcess',
    name: 'newProcess',
    component: () => import('@/views/workbench/new-process/index.vue'),
    meta: {
      keepAlive: false,
      title: '新建流程'
    }
  },
  {
    path: '/flowcenter', // 路由未匹配到，进入这个
    name: 'flowcenter',
    component: () => import('@/views/office/flowcenter.vue'),
    meta: {
      keepAlive: false,
      title: '流程中心'
    }
  },
  {
    path: '/officepage', // 路由未匹配到，进入这个
    name: 'WpsPage',
    component: () => import('@/components/wps/index.vue'),
    meta: {
      keepAlive: false,
      title: '文档预览'
    }
  },
  {
    path: '/500', // 路由未匹配到，进入这个
    name: 'page500',
    component: () => import('@/components/exception/500.vue'),
    meta: {
      keepAlive: false,
      title: '500'
    }
  }
  // {
  //   path: "/:currentPath(.*)*", // 路由未匹配到，进入这个
  //   redirect: (_) => {
  //     return {
  //       path: "/404"
  //     };
  //   },
  // },
];

export interface AutoOperationModel {
  time: string;
  type: string;
}

export interface ParamOptionModel {
  name: string;
  text: string;
  formImport?: boolean;
  type: ParamTypeModel;
  defaultValue: string | number;
  global: boolean;
  innerParams?: InnerParamModel[];
}

export const ParamOptionData: ParamOptionModel = {
  name: '',
  text: '',
  type: {
    text: '',
    value: ''
  },
  defaultValue: '',
  global: false
};

export interface ParamTypeModel {
  text: string;
  value: string;
  valueItems?: ValueItemModel[];
  compares?: string[];
}

export interface InnerParamModel {
  key: string;
  text?: string;
  paramOptionName: string;
  type: string;
}

export interface ValueItemModel {
  text: string;
  code: string;
  disabled: boolean;
  children?: ValueItemModel[];
}

export interface CodeSettingModel {
  split: string;
  parts: CodePartModel[];
}
export interface CodePartModel {
  type: ItemType | '';
}

export interface ConstantsPart extends CodePartModel {
  type: ItemType.CONSTANTS;
  value: string;
}

export interface VariablePart extends CodePartModel {
  type: ItemType.VARIABLE;
  varName: string;
}

export interface SerialNumberPart extends CodePartModel {
  type: ItemType.SERIAL_NUMBER;
  serialNumber: SerialNumber;
}

export enum ItemType {
  CONSTANTS = '常量',
  VARIABLE = '参数',
  SERIAL_NUMBER = '流水号'
}

export interface SerialNumber {
  type: SerialType | '';
  varName?: string;
  length: Number;
}

export enum SerialType {
  DATE = '日期',
  VARIABLE = '参数'
}

export interface FlowConfigModel {
  formId: string;
  autoOperations: AutoOperationModel[];
  paramsOptions: ParamOptionModel[];
  codeSetting?: CodeSettingModel;
  titleVarName: string | null;
  starterVarName: string | null;
  starterGroupVarName: string | null;
}

export function CodeSettingData(): CodeSettingModel {
  return {
    split: '',
    parts: []
  }
};

export function FlowConfigData(): FlowConfigModel {
  return {
    formId: '',
    autoOperations: [],
    paramsOptions: [],
    codeSetting: CodeSettingData(),
    titleVarName: null,
    starterVarName: null,
    starterGroupVarName: null
  }
};

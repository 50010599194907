import { createApp } from 'vue';
import App from './App.vue';
import { store, key } from './store';
import router from './router';
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
import './assets/css/global/index.less';
import './assets/css/global/vant-variable.less'; //引入全局自定义css
import 'amfe-flexible/index.js';
import i18n from './lang';
// import Vconsole from 'vconsole';
// const vConsole: any = new Vconsole();

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';

const app = createApp(App);
// app.use(vConsole)
app.use(i18n);
app.use(store, key);
app.use(router);
app.use(Lazyload);
app.use(Vant);
app.use(VXETable);
app.mount('#app');

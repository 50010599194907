import { ParamOptionModel } from '../flow/FlowConfigModel';
import { FormPropertiesModel, FormPropertiesData } from './FormPropertiesModel';
import { WidgetModel } from './WidgetModel';

export interface FormModel {
  id: string;
  createDate?: number;
  updateDate?: number;
  code: string;
  name: string;
  status: string;
  description: string;
  applicationId: string;
  container: any;
  version: number | null;
}

export const FormData: FormModel = {
  id: '',
  code: '',
  name: '',
  status: '',
  description: '',
  applicationId: '',
  container: {
    paramsOptions: [],
    properties: FormPropertiesData,
    components: [],
    actions: []
  },
  version: null
};
export interface HtmlModel {
  node: {
    name: string;
    props: { name: string; value: any }[];
    children: HtmlChildModel[];
    [x: string]: any;
  };
}
export interface HtmlChildModel {
  name: string;
  props: { name: string; value: any }[];
  content?: HtmlContentModel;
  children: HtmlChildModel[];
  [x: string]: any;
}
interface HtmlContentModel {
  type: HtmlTypeEnum;
  value: string | Record<string, any>;
  loop?: boolean;
  id?: string;
  [x: string]: any;
}
export enum HtmlTypeEnum {
  CONSTANT = 'constants',
  COMPONENTS = 'components',
  FORMULA = 'formula',
  DATASOURCE = 'datasource'
}
